import classnames from 'classnames';
import { useMemo, type FunctionComponent, type HTMLAttributes } from 'react';

import styles from './Skeleton.module.scss';

export type SkeletonTheme = 'dark' | 'default' | 'none';

export type SkeletonType =
  | 'bigButton'
  | 'button'
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'largeParagraph'
  | 'largest'
  | 'paragraph'
  | 'smallButton'
  | 'smallParagraph'
  | 'tinyParagraph';

interface SkeletonProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * Add a custom class name string (`my-class`, `"my-class my-other-class"`).
   */
  className?: string;
  /**
   * The height of the placeholder.
   * Can be any valid CSS height string (e.g. `40px`, `10%` or `2em`).
   */
  height?: string | number;
  /**
   * To adjust background darkness.
   */
  theme?: SkeletonTheme;
  /**
   * The type of the component to be mimicked.
   */
  type?: SkeletonType;
  /**
   * The width of the placeholder.
   * Can be any valid CSS width string (e.g. `50vw`, `10%` or `3rem`).
   */
  width?: string | number;
}

const themes: { [key in SkeletonType]?: SkeletonTheme } = {
  bigButton: 'dark',
  button: 'dark',
  h1: 'dark',
  largest: 'dark',
  smallParagraph: 'dark',
};

export const Skeleton: FunctionComponent<SkeletonProps> = ({
  className,
  height,
  style,
  theme: themeProp,
  type,
  width,
  ...forwardedProps
}) => {
  const computedClassNames = useMemo(() => {
    const theme = themeProp || themes[type as SkeletonType] || 'default';

    return classnames(
      styles.skeleton,
      type ? styles[type] : undefined,
      {
        [styles.themeDark]: theme === 'dark',
        [styles.themeDefault]: theme === 'default',
      },
      className,
    );
  }, [type, themeProp, className]);

  return (
    <div
      className={computedClassNames}
      style={{ height, width, ...style }}
      {...forwardedProps}
    />
  );
};

Skeleton.displayName = 'Skeleton';
