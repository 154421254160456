import { useEffect } from 'react';

export const useUserTiming = (measureName: string) => {
  const safeMeasureName = measureName.replace(/\s/g, '-');
  const startName = `${safeMeasureName}Start`;
  const endName = `${safeMeasureName}End`;

  if (typeof window !== 'undefined') {
    window.performance.mark(startName);
  }

  useEffect(() => {
    window.performance.mark(endName);
    window.performance.measure(safeMeasureName, startName, endName);
  });
};
