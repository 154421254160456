import classnames from 'classnames';
import { forwardRef, type HTMLAttributes, type ReactNode } from 'react';

import { type TamagoshiComponent } from '@/core/tamagoshiv2/interfaces';

import styles from './Typography.module.scss';

// TODO Migrate the literals to const arrays

export const TextVariants = {
  body1: 'body1',
  body2: 'body2',
  small: 'small',
  tiny: 'tiny',
} as const;

export const TitleVariants = {
  display: 'display',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
} as const;

export type TextVariant = keyof typeof TextVariants;
export type TitleVariant = keyof typeof TitleVariants;

// TODO Rename `type` semantics to `weight`?

export const TextTypes = {
  bold: 'bold',
  regular: 'regular',
  semiBold: 'semiBold',
} as const;

export const TitleTypes = {
  title: 'title',
} as const;

type TypographyVariantProps<TVariant> = TVariant extends TextVariant
  ? {
      asComponent?: 'div' | 'li' | 'p' | 'span' | 'td' | 'th';
      type?: keyof typeof TextTypes;
      variant?: TVariant;
    }
  : {
      /** The HTML element to use for the root node */
      asComponent?: 'div' | 'h1' | 'h2' | 'h3' | 'h4' | 'span';
      /** The type of the text */
      type?: keyof typeof TitleTypes;
      /** The variant of the text */
      variant?: TVariant;
    };

type TypographyProps<TVariant = TextVariant | TitleVariant> =
  TamagoshiComponent &
    TypographyVariantProps<TVariant> &
    // NOTE The whole typings need refactoring so that `asComponent` leads the
    //      children properties that are available ie. if `asComponent` is set
    //      to `th` then `scope` should be available, but not if it is `div`.
    //      Until then, we add `title` manually so that most components can have
    //      an accessible title.
    Pick<HTMLAttributes<HTMLElement>, 'title'> &
    (
      | { children: ReactNode; dangerouslySetInnerHTML?: never }
      | { children?: never; dangerouslySetInnerHTML: { __html: string } }
    );

type TypographyUtilityProps<TVariant = TextVariant | TitleVariant> =
  TypographyProps<TVariant> & { asComponent?: never };

const DEFAULTS =
  // prettier-ignore
  {
    div:  { type: 'regular', variant: 'body1' },
    h1:   { type: 'title',   variant: 'h1' },
    h2:   { type: 'title',   variant: 'h2' },
    h3:   { type: 'title',   variant: 'h3' },
    h4:   { type: 'title',   variant: 'h4' },
    li:   { type: 'regular', variant: 'body1' },
    p:    { type: 'regular', variant: 'body1' },
    span: { type: 'regular', variant: 'body1' },
    td:   { type: 'regular', variant: 'body1' },
    th:   { type: 'regular', variant: 'body1' },
  } as const satisfies {
    [key in keyof HTMLElementTagNameMap]?:
      | { type: keyof typeof TextTypes; variant: keyof typeof TextVariants }
      | { type: keyof typeof TitleTypes; variant: keyof typeof TitleVariants };
  };

/**
 * Display a text using the Tamagoshi V2 typography.
 *
 * Links, strong and emphasis semantics within the component will be styled
 * accordingly. Default values for this component are based on the tag provided
 * with the `asComponent` property.
 *
 * It is recommended to use the utility components instead.
 */
export const Typography = forwardRef<any, TypographyProps>(
  ({ asComponent: Tag = 'div', type, variant, className, ...props }, ref) => {
    const calculatedType = type || DEFAULTS[Tag].type;
    const calculatedVariant = variant || DEFAULTS[Tag].variant;
    const classes = classnames(
      className,
      styles.text,
      styles[calculatedType],
      styles[calculatedVariant],
    );
    return <Tag ref={ref} className={classes} {...props} />;
  },
);

Typography.displayName = 'Typography';

/** Render a block element with the proper default typography. */
export const Div = forwardRef<HTMLDivElement, TypographyUtilityProps>(
  (props, ref) => <Typography {...props} asComponent="div" ref={ref} />,
);
Div.displayName = 'Div';

/** Render a list item with the proper default typography. */
export const Li = forwardRef<
  HTMLLIElement,
  TypographyUtilityProps<TextVariant>
>((props, ref) => <Typography {...props} asComponent="li" ref={ref} />);
Li.displayName = 'Li';

/** Render a paragraph with the proper default typography. */
export const P = forwardRef<
  HTMLParagraphElement,
  TypographyUtilityProps<TextVariant>
>((props, ref) => <Typography asComponent="p" {...props} ref={ref} />);
P.displayName = 'P';

type FilteredHTMLTableCellElement = Partial<
  Pick<HTMLTableCellElement, 'colSpan' | 'headers' | 'rowSpan' | 'scope'>
>;

/** Render a table header cell with the proper default typography. */
export const Th = forwardRef<
  HTMLTableCellElement,
  TypographyUtilityProps<TextVariant> & FilteredHTMLTableCellElement
>((props, ref) => <Typography asComponent="th" {...props} ref={ref} />);
Th.displayName = 'Th';

/** Render a table data cell with the proper default typography. */
export const Td = forwardRef<
  HTMLTableCellElement,
  TypographyUtilityProps<TextVariant> & FilteredHTMLTableCellElement
>((props, ref) => <Typography asComponent="td" {...props} ref={ref} />);
Td.displayName = 'Td';

/** Render a inline element with the proper default typography. */
export const Span = forwardRef<HTMLSpanElement, TypographyUtilityProps>(
  (props, ref) => <Typography asComponent="span" {...props} ref={ref} />,
);
Span.displayName = 'Span';

/** Render a title with the proper default typography. */
export const Title = forwardRef<
  HTMLDivElement,
  TypographyUtilityProps<TitleVariant>
>((props, ref) => (
  <Typography
    asComponent="div"
    {...props}
    type="title"
    variant="display"
    ref={ref}
  />
));
Title.displayName = 'Title';

/** Render a level-1 heading with the proper default typography. */
export const H1 = forwardRef<
  HTMLHeadingElement,
  TypographyUtilityProps<TitleVariant>
>((props, ref) => <Typography asComponent="h1" {...props} ref={ref} />);
H1.displayName = 'H1';

/** Render a level-2 heading with the proper default typography. */
export const H2 = forwardRef<
  HTMLHeadingElement,
  TypographyUtilityProps<TitleVariant>
>((props, ref) => <Typography asComponent="h2" {...props} ref={ref} />);
H2.displayName = 'H2';

/** Render a level-3 heading with the proper default typography. */
export const H3 = forwardRef<
  HTMLHeadingElement,
  TypographyUtilityProps<TitleVariant>
>((props, ref) => <Typography asComponent="h3" {...props} ref={ref} />);
H3.displayName = 'H3';

/** Render a level-4 heading with the proper default typography. */
export const H4 = forwardRef<
  HTMLHeadingElement,
  TypographyUtilityProps<TitleVariant>
>((props, ref) => <Typography asComponent="h4" {...props} ref={ref} />);
H4.displayName = 'H4';
